/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.$ = window.jQuery = require("jquery");
    window.bootstrap = require("bootstrap5/dist/js/bootstrap.bundle");
    window.lazysizes = require("lazysizes");
} catch (e) {}

$.ajaxSetup({
    headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        "X-Requested-With": "XMLHttpRequest",
    },
});

$(window).on('load scroll resize', function () {
    var hh = $('header').innerHeight();
    $('.hero-search-banner').css({'min-height': 'calc(100vh - '+ hh +'px)'});
    $('.header-offset').css({'padding-top': hh});
})

window.onSubmitCaptcha = function (token) {
    $("#reCaptchaForm")
        .find("button[type=submit]")
        .prop("disabled", true)
        .html('<i class="fas fa-arrows-rotate fa-spin"></i>');
    $("#reCaptchaForm").trigger("submit");
};

$("input[type=tel], input[inputmode=numeric]").on("input", function (e) {
    $(this).val(
        $(this)
            .val()
            .replace(/[^0-9]/g, "")
    );
});

$("form")
    .not("#reCaptchaForm")
    .on("submit", function (event) {
        if ($(this).find(":invalid").length) {
            event.preventDefault();
            $(this).find(":invalid").first().focus();
        } else {
            $(this)
                .find("button[type=submit]")
                .prop("disabled", true)
                .html('<i class="fas fa-arrows-rotate fa-spin"></i>');
        }
    });

document.addEventListener('lazybeforeunveil', function(e){
    var bg = e.target.getAttribute('data-bg');
    if (bg) {
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});
